import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProfileComponent} from './profile.component';
import { GuardRouteService as AuthGuard } from '../../authentication/Services/guard-route.service';
import { ProfileDataComponent } from './profile-data/profile-data.component';


const routes: Routes = [
  // {path: '', redirectTo: 'home'},
  {path: '', component: ProfileComponent, canActivate: [AuthGuard], children: [
    {path: '', redirectTo: 'profile-data'},
    {path: 'profile-data', component: ProfileDataComponent},
    {path: 'reservation', loadChildren: () => import('./reservation/reservation.module').then(m => m.ReservationModule)},
    {path: 'favourite', loadChildren: () => import('./favourite/favourite.module').then(m => m.FavouriteModule)},
    {path: 'yourCars', loadChildren: () => import('./profile-share-your-car/profile-share-your-car.module').then(m => m.ProfileShareYourCarModule)},
    {path: 'changePassword', loadChildren: () => import('./profile-change-password/profile-change-password.module').then(m => m.ProfileChangePasswordModule)},
    {path: 'yourCars', loadChildren: () => import('./profile-share-your-car/profile-share-your-car.module').then(m => m.ProfileShareYourCarModule)}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class ProfileRoutingModule { }
