<div class="container">
  <div class="profile">
    <div class="row">

      <div class="col-lg-3 col-12 ">
        <app-side-menu></app-side-menu>
      </div>



      <div class="col-lg-9 col-12 customOverflow">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
