
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/Services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GuardRouteService implements CanActivate {

  constructor(private authService: AuthenticationService,
     private router: Router) { }


  canActivate() {
    if (!this.authService.isAuth()) {
      this.router.navigate(['pages/home']);
      return false;
    } else {
      return true;
    }
  };


}
