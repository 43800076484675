import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import {InputTextModule} from 'primeng/inputtext';
import { PagesSharedModule } from '../pagesShared.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ProfileDataComponent } from './profile-data/profile-data.component';
import {DialogModule} from 'primeng/dialog';




@NgModule({
  declarations: [ProfileComponent, ProfileDataComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    InputTextModule,
    PagesSharedModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,

  ]
})
export class ProfileModule { }
