import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { AuthenticationService } from 'src/app/authentication/Services/authentication.service';

@Component({
  selector: 'app-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.scss']
})
export class ProfileDataComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    private pagesService: PagesService,
    public translate: TranslationService,
    private router: Router,
    private authService: AuthenticationService,
    public sharedService: SharedService,) {

  }

  fullName:any;
  email:any;
  address:any;
  phone:any;

  photo:any;

  display: boolean = false;
  value1: string ;

  //countries data
  countries: any[];
  selectedCountry: any;

    //cities data
    cities: any[];
    selectedCity: any;

    //user data
    userData:any;

    //key to disable save btn
    key:boolean= false;

    showDialog() {
        this.display = true;
    }

    hideDialog() {
      this.display = false;
  }

  ngOnInit(): void {
    this.spinner.show();

    //get all countries
    this.pagesService.getCountry().subscribe(
      (response: any) => {
        console.log('country => ', response);
        this.countries = response.data;
      },
      (error) => {
        console.log(error);
      }
    );

    // get user data
    this.pagesService.getuserData().subscribe(
      (response: any) => {
        console.log('user data => ', response);
        this.userData = response.data;

        this.fullName = this.userData.name;
        // this.email = this.userData.
        this.getContries();
        this.selectedCountry = this.userData.country;
        this.onGetCities();
        this.selectedCity = this.userData.city;
        this.address = this.userData.address;
        this.phone = this.userData.phone;
        this.email = this.userData.email;
        this.spinner.hide();
      },
      (error:any) => {
        console.log(error);
      }
    );
  }


  //get upload file
  uploadFile=(e:any)=>{
    let me = this;
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //me.modelvalue = reader.result;
      // console.log(reader.result);
      me.photo = String(reader.result).split(',')[1];
      console.log(me.photo);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

    //on change country
    onChangeCountry(){
      this.onGetCities();
    }


    getContries(){
      this.pagesService.getCountry().subscribe(
        (response: any) => {
          console.log('country => ', response);
          this.countries = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    }
    //get cities
    onGetCities() {
      this.pagesService.getCity(this.selectedCountry).subscribe(
        (response: any) => {
          this.cities = response.data;
          console.log("this.cities",this.cities);
        },
        (error: any) => {
          console.log(error);
        }
      )
    };

    onChangeUserData=()=>{
      this.key = true;
      let userData =
        {
          email: this.email ,
          name : this.fullName,
          cityId : this.selectedCity,
          address: this.address,
          phoneNumber: this.phone,
          password: "",
          confirmPassword: "",
          photo: this.photo ? this.photo : ""
      }
      console.log("userdata",userData);


      this.pagesService.updateUser(userData).subscribe(
        (response: any) => {
          console.log('userData ', response);
          this.sharedService.useData = response.data;
          this.authService.setUser(this.sharedService.useData);
          this.key = false;
          // console.log("key",this.key);

          // this.reloadComponent();

        },
        (error) => {
          console.log(error);
          this.key= false;
          this.sharedService.stringErrorMessage.next("server error, try later !");
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        }
      );
    };

    reloadComponent() {
      let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
      }

}
