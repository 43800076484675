<div class="profile">
    <div class="row">
        <div class="col-12">
          <div class="header">
            <p>{{translate.data.profile.account}}</p>
            <div class="d-lg-inline d-none" (click)="uploader.click()">
              <p>{{translate.data.profile.imageChange}}</p>
              <img src="../../../assets/profile/icons/Group 10361.svg" alt="">
              <input
                hidden
                type="file"
                #uploader
                (change)="uploadFile($event)"/>
            </div>
          </div>
          <div [ngClass]="{'body': translate.is_arabic, 'bodyEn': !translate.is_arabic}">
            <div class="col-12 d-flex d-lg-none con-r">
              <div class="col-12 proimg"><img src="{{sharedService.ipRootImg + userData?.image}}" alt=""></div>
              <div class="d-lg-none d-flex col-12 proicon" >
                <div class="d-lg-none d-flex photoch "  (click)="uploader.click()">
                <p class="psm" >{{translate.data.profile.imageChange}}</p>
                <img class="imgsm" src="../../../assets/profile/icons/Group 10361.svg" alt="">
              </div>
            </div>
            </div>
            <form class="d-flex flex-wrap">
              <div class="col-lg-6 col-12 my-3">
                <label  for="">{{translate.data.profile.name}}</label>
                <input type="text" [(ngModel)]="fullName" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-lg-6 col-12 my-3">
                <label for="">{{translate.data.profile.email}}</label>
                <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-lg-6 col-12 my-3">
                <label for="">{{translate.data.profile.country}}</label>
                <select  class="form-select form-select-lg mb-3"
                [(ngModel)]="selectedCountry"
                  aria-label=".form-select-lg example"
                  (change)="onChangeCountry()"
                  [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let country of countries" value={{country?.id}}>{{country[translate.data.requestdData.genName]}}</option>
                </select>

              </div>
              <div class="col-lg-6 col-12 my-3">
                <label for="">{{translate.data.profile.city}}</label>
                <select  class="form-select form-select-lg mb-3"
                [(ngModel)]="selectedCity"
                  aria-label=".form-select-lg example"
                  [ngModelOptions]="{standalone: true}">
                  <option value="" selected  hidden >{{translate.data.profile.city}}</option>
                  <option *ngFor="let city of cities" value={{city?.id}}>{{city[translate.data.requestdData.genName]}}</option>
                </select>
              </div>
              <div class="col-lg-6 col-12 my-3">
                <label for="">{{translate.data.profile.address}}</label>
                <input type="text" [(ngModel)]="address" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-lg-6 col-12 my-3">
                <label for="">{{translate.data.profile.phone}}</label>
                <input type="text" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-12 edit">
                <button type="submit" [disabled]="key" (click)="onChangeUserData()">
                  {{translate.data.profile.update}}
                </button>
              </div>
            </form>
          </div>
        </div>



        <!-- <div class=" col-12 mt-5 ">
          <div class="header">
            <p>{{translate.data.profile.payment}}</p>
          </div>
          <div class="body body-footer justify-content-lg-start justify-content-center">
            <div class="card-master">
              <img class="relative" src="../../../assets//profile//master/Group 10634.png" alt="">
              <img class="footer-mastercard" src="../../../assets/profile/master/mastercard.png" alt="">
              <p class="pr-1">Shopping Card</p>
              <p class="pr-2"><span>2536</span><span>3587</span><span>5300</span><span>5682</span></p>
               <div class="d-flex flex-wrap pc">
                 <div class="pc-34">
                  <p class="pr-3">CARD HOLDER</p>
                  <p class="pr-4">Janni Kavin</p>
                 </div>
                 <div class="pc-34">
                  <p class="pr-5">EXPIRES</p>
                  <p class="pr-6">01 / 12</p>
                 </div>
                 <div class="pc-34">
                  <p class="pr-7">CVV</p>
                  <p>...</p>
                 </div>
               </div>
               <div class="" >
                <button class="edit-card"><img src="../../../assets/profile/icons/pencil.png">تعديل</button>
               <button class="remove"><img src="../../../assets/profile/icons/garbage.png">حذف</button>
               </div>

            </div>
            <img class="modal-btn d-lg-inline d-none" (click)="showDialog()"  label="Show" src="../../../assets/profile/button/753345.svg" alt="">
          </div>
        </div>

        <div class="d-lg-none col-12 mt-5 ">
          <div class="header">
            <p>طريقة الدفع جديدة</p>
          </div>
          <div class="body body-footer justify-content-center">
            <img class="modal-btn" (click)="showDialog()"  label="Show" src="../../../assets/profile/button/753345.svg" alt="">
          </div>
        </div>


      </div> -->
</div>

<!-- ------------------------------------- Modal ------------------------------------------------- -->
<!-- <p-dialog  [(visible)]="display" contentStyleClass="mydialog" [closable]="false">
    <ng-template pTemplate="header" styleClass="header-img">
      <div class="header-img">
        <img (click)="hideDialog()" src="../../../assets/profile/modalformicons/753345.svg" alt="">
      </div>
  </ng-template>

    <form >
      <div class="fram">
        <div class="payment flex-wrap">
          <div class="title">
            <input type="radio" id="male" name="gender" value="حساب بنكي">
            <label for="male">حساب بنكي</label><br>
            <p>تحويل الأموال بأمان باستخدام حسابك المصرفي. Visa ، Maestro ، Discover ، American Express.</p>
            <p class="number">0000 0000 0000 0000</p>
          </div>

          <div class="icons">
            <img src="../../../assets/profile/payment/credit-cards_amex.svg" alt="">
            <img class="master" src="../../../assets/profile/payment/credit-cards_mastercard.svg" alt="">
            <img src="../../../assets/profile/payment/credit-cards_visa.svg" alt="">
          </div>
        </div>

        <div class="form">

          <span class="p-input-icon-left">
            <i><img src="../../../assets/profile/modalformicons/card_icon.svg" alt=""></i>
            <label for="">رقم الحساب</label>
            <input type="text" pInputText  class="credit" >
        </span>

        <div class="inputs">
          <div>
            <label for="">اسم حامل البطاقة</label>
            <input class="w-6" type="text" pInputText />
          </div>
          <div>
            <label for="">تاريخ الانتهاء</label>
            <input class="w-2" type="text" pInputText placeholder="شهر / سنة"/>
          </div>
          <div>
            <span class="p-input-icon-left">
              <i><img class="editWidth" src="../../../assets/profile/modalformicons/info.svg" alt=""></i>
              <label for="">رمز الامان</label>
              <input class="w-2" type="text" pInputText  >
          </span>
          </div>
        </div>

        </div>

      </div>
    </form>

    <p-footer>
      <div class="footer-modal">
        <button class="color">حذف</button>
        <button class="noColor" (click)="hideDialog()" >الغاء</button>
      </div>
  </p-footer>
  </p-dialog> -->
